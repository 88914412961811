import Vue from 'vue';
// // import LTE from '../common/assets/js/lte.js'
import './assets/index.less';
// // import '../common/assets/index.less'
import App from './App.vue';
// // Vue.use(LTE)
Vue.config.productionTip = false;
new Vue({
    el: '#app',
    render: h => h(App)
});
