<template>
  <div class="login-box">
    <div class="card card-outline card-primary">
      <div class="card-header text-center">
        <b class="h3">MICO Agency Admin</b>
      </div>
      <div class="card-body">
        <form action="/login.do" method="post" @submit.prevent="login">
          <input type="hidden" :name="csrfName" :value="csrfValue">
          <input type="hidden" name="g-recaptcha-response" :value="gRecaptchaResponse">
          <input type="hidden" name="device-token-v2" :value="deviceToken">
          <!--
          <input type="hidden" name="start_ts" :value="start_ts">
          <input type="hidden" name="end_ts" :value="end_ts">
          -->
          <div class="input-group mb-3">
            <input name="email" id="email" v-model.trim="email" type="email" class="form-control" placeholder="Email" autocomplete="email" :autofocus="email?false:true" required>
            <label class="input-group-append" for="email">
              <div class="input-group-text">
                <span class="fa-solid fa-envelope"></span>
              </div>
            </label>
          </div>
          <div class="input-group mb-3">
            <input name="password" id="password" v-model.trim="password" type="password" class="form-control" placeholder="Password" autocomplete="current-password" :autofocus="email?true:false" required>
            <label class="input-group-append" for="password">
              <div class="input-group-text">
                <span class="fa-solid fa-lock"></span>
              </div>
            </label>
          </div>
          <div v-if="captchaEnabled" class="input-group mb-3">
            <input name="captcha" id="captcha" v-model.trim="captcha" type="text" class="form-control" placeholder="Captcha" required>
            <label class="input-group-append" for="password">
              <img width="100" height="38" class="captcha" :src="captchaUrl" title="Click to change captcha" @click="changeCaptcha" alt="captcha">
              <!--<div class="input-group-text">
                <span class="fa-solid fa-lock"></span>
              </div>
              -->
            </label>
          </div>
          <p v-if="successMessage" class="login-box-msg text-left text-success ml-n3">
            <i class="fa-solid fa-circle-check"></i>
            <span v-text="successMessage"></span>
          </p>
          <p v-if="failedMessage" class="login-box-msg text-left text-danger ml-n3">
            <i class="fa-solid fa-circle-exclamation"></i>
            <span v-text="failedMessage"></span>
          </p>
          <p v-if="tfMessage" class="login-box-msg text-left text-danger ml-n3">
            <i class="fa-solid fa-circle-exclamation"></i>
            <span v-text="tfMessage"></span>
          </p>
          <div class="row">
            <div class="col-8">
              <div class="icheck-primary">
                <input type="checkbox" id="remember" v-model="rememberMe" true-value="on" false-value="off" name="remember-me">
                <label for="remember">
                  Remember Me
                </label>
              </div>
            </div>

            <div class="col-4">
              <button
                :disabled="!email || !password || logining || (captchaEnabled && !captcha)"
                class="g-recaptcha btn btn-primary btn-block text-nowrap"
                type="submit"
              >
                <span v-if="logining" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Sign In
              </button>
            </div>

          </div>
        </form>
        <div v-if="false" class="social-auth-links text-center mt-2 mb-3">
          <a href="#" class="btn btn-block btn-primary">
            <i class="fab fa-facebook mr-2"></i> Sign in using Facebook
          </a>
          <a href="#" class="btn btn-block btn-danger">
            <i class="fab fa-google-plus mr-2"></i> Sign in using Google+
          </a>
        </div>

        <p v-if="false" class="mb-1">
          <a href="forgot-password.html">I forgot my password</a>
        </p>
        <p v-if="false" class="mb-0">
          <a href="register.html" class="text-center">Register a new membership</a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export { default } from './app.js'
</script>
<style lang="less" scoped>
.captcha {
  width: 100px;
  height: 38px;
  cursor: pointer;
}
</style>