var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "login-box" }, [
    _c("div", { staticClass: "card card-outline card-primary" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "form",
          {
            attrs: { action: "/login.do", method: "post" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.login.apply(null, arguments)
              },
            },
          },
          [
            _c("input", {
              attrs: { type: "hidden", name: _vm.csrfName },
              domProps: { value: _vm.csrfValue },
            }),
            _vm._v(" "),
            _c("input", {
              attrs: { type: "hidden", name: "g-recaptcha-response" },
              domProps: { value: _vm.gRecaptchaResponse },
            }),
            _vm._v(" "),
            _c("input", {
              attrs: { type: "hidden", name: "device-token-v2" },
              domProps: { value: _vm.deviceToken },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "input-group mb-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.email,
                    expression: "email",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  name: "email",
                  id: "email",
                  type: "email",
                  placeholder: "Email",
                  autocomplete: "email",
                  autofocus: _vm.email ? false : true,
                  required: "",
                },
                domProps: { value: _vm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.email = $event.target.value.trim()
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._v(" "),
              _vm._m(1),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group mb-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.password,
                    expression: "password",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  name: "password",
                  id: "password",
                  type: "password",
                  placeholder: "Password",
                  autocomplete: "current-password",
                  autofocus: _vm.email ? true : false,
                  required: "",
                },
                domProps: { value: _vm.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.password = $event.target.value.trim()
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm._v(" "),
              _vm._m(2),
            ]),
            _vm._v(" "),
            _vm.captchaEnabled
              ? _c("div", { staticClass: "input-group mb-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.captcha,
                        expression: "captcha",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "captcha",
                      id: "captcha",
                      type: "text",
                      placeholder: "Captcha",
                      required: "",
                    },
                    domProps: { value: _vm.captcha },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.captcha = $event.target.value.trim()
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "input-group-append",
                      attrs: { for: "password" },
                    },
                    [
                      _c("img", {
                        staticClass: "captcha",
                        attrs: {
                          width: "100",
                          height: "38",
                          src: _vm.captchaUrl,
                          title: "Click to change captcha",
                          alt: "captcha",
                        },
                        on: { click: _vm.changeCaptcha },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.successMessage
              ? _c(
                  "p",
                  { staticClass: "login-box-msg text-left text-success ml-n3" },
                  [
                    _c("i", { staticClass: "fa-solid fa-circle-check" }),
                    _vm._v(" "),
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.successMessage) },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.failedMessage
              ? _c(
                  "p",
                  { staticClass: "login-box-msg text-left text-danger ml-n3" },
                  [
                    _c("i", { staticClass: "fa-solid fa-circle-exclamation" }),
                    _vm._v(" "),
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.failedMessage) },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.tfMessage
              ? _c(
                  "p",
                  { staticClass: "login-box-msg text-left text-danger ml-n3" },
                  [
                    _c("i", { staticClass: "fa-solid fa-circle-exclamation" }),
                    _vm._v(" "),
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.tfMessage) },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-8" }, [
                _c("div", { staticClass: "icheck-primary" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.rememberMe,
                        expression: "rememberMe",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "remember",
                      "true-value": "on",
                      "false-value": "off",
                      name: "remember-me",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.rememberMe)
                        ? _vm._i(_vm.rememberMe, null) > -1
                        : _vm._q(_vm.rememberMe, "on"),
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.rememberMe,
                          $$el = $event.target,
                          $$c = $$el.checked ? "on" : "off"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.rememberMe = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.rememberMe = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.rememberMe = $$c
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "remember" } }, [
                    _vm._v("\n                Remember Me\n              "),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "g-recaptcha btn btn-primary btn-block text-nowrap",
                    attrs: {
                      disabled:
                        !_vm.email ||
                        !_vm.password ||
                        _vm.logining ||
                        (_vm.captchaEnabled && !_vm.captcha),
                      type: "submit",
                    },
                  },
                  [
                    _vm.logining
                      ? _c("span", {
                          staticClass: "spinner-border spinner-border-sm",
                          attrs: { role: "status", "aria-hidden": "true" },
                        })
                      : _vm._e(),
                    _vm._v("\n            Sign In\n            "),
                  ]
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        false
          ? _c(
              "div",
              { staticClass: "social-auth-links text-center mt-2 mb-3" },
              [_vm._m(3), _vm._v(" "), _vm._m(4)]
            )
          : _vm._e(),
        _vm._v(" "),
        false
          ? _c("p", { staticClass: "mb-1" }, [
              _c("a", { attrs: { href: "forgot-password.html" } }, [
                _vm._v("I forgot my password"),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        false
          ? _c("p", { staticClass: "mb-0" }, [
              _c(
                "a",
                {
                  staticClass: "text-center",
                  attrs: { href: "register.html" },
                },
                [_vm._v("Register a new membership")]
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "card-header text-center" }, [
      _c("b", { staticClass: "h3" }, [_vm._v("MICO Agency Admin")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "label",
      { staticClass: "input-group-append", attrs: { for: "email" } },
      [
        _c("div", { staticClass: "input-group-text" }, [
          _c("span", { staticClass: "fa-solid fa-envelope" }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "label",
      { staticClass: "input-group-append", attrs: { for: "password" } },
      [
        _c("div", { staticClass: "input-group-text" }, [
          _c("span", { staticClass: "fa-solid fa-lock" }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      { staticClass: "btn btn-block btn-primary", attrs: { href: "#" } },
      [
        _c("i", { staticClass: "fab fa-facebook mr-2" }),
        _vm._v(" Sign in using Facebook\n        "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      { staticClass: "btn btn-block btn-danger", attrs: { href: "#" } },
      [
        _c("i", { staticClass: "fab fa-google-plus mr-2" }),
        _vm._v(" Sign in using Google+\n        "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }