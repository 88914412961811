import $ from 'jQuery'
import TDID from '_TDID'
import grecaptcha from 'grecaptcha'

export default {
  name: 'Login',
  data() {
    return {
      csrfName: $('#csrf').attr('name'),
      csrfValue: $('#csrf').attr('content'),
      successMessage: $('#success-message').val(),
      failedMessage: $('#failed-message').val(),
      tfMessage: '',
      recaptchaSite: $('#recaptcha-site').val(),
      captchaEnabled: $('#captcha').val() === 'true',
      gRecaptchaResponse: '',
      email: $('#email').val() || '',
      password: '',
      captcha: undefined,
      rememberMe: false,
      logining: false,
      captchaUrl: '/captcha.gif',
      // start_ts: 0,
      // end_ts: 0,
      deviceToken: '',
    }
  },
  methods: {
    async login() {
      try {
        this.logining = true
        // this.start_ts = Date.now()
        // await grecaptcha.enterprise.ready()
        // $('script[src*=google],script[src*=gstatic]').remove()
        // this.start_ts = await $.getJSON('/ts')
        // $.getScript(`//www.google.com/recaptcha/enterprise.js?render=${this.recaptchaSite}`, () => {
        //   window.grecaptcha.enterprise.ready(async () => {
            // console.log('start_ts', this.start_ts)
            // this.gRecaptchaResponse = await grecaptcha.enterprise.execute(this.recaptchaSite, {action: 'login'})
            // console.log(this.gRecaptchaResponse)
            // this.gRecaptchaResponse = await grecaptcha.enterprise.execute(this.recaptchaSite, {action: 'login'})
            // console.log(this.gRecaptchaResponse)

            // console.log(window.grecaptcha)
            this.gRecaptchaResponse = await grecaptcha.enterprise.execute(this.recaptchaSite, {action: 'login'})
            // console.log(this.gRecaptchaResponse)
            // this.end_ts = await $.getJSON('/ts')
            // console.log('end_ts', this.end_ts)
            try {
              await new Promise((resolve, reject) => {
                TDID.init({
                  channel: 100049,
                  apiUrl: process.env.TURING_FRAUD_URL,
                }, ({err, ret, seq, ticketID}) => {
                  const data = {ret, seq, ticketID}
                  if (ret !== 0) {
                    const error = new Error(err)
                    error.data = data
                    reject(error)
                  } else {
                    resolve(data)
                  }
                })
              })
              const { deviceToken, ret: code } = await new Promise((resolve, reject) => {
                TDID.getDeviceTokenV2(({err, ret, seq, deviceToken}) => {
                  const data = {ret, seq, deviceToken}
                  if (ret !== 0) {
                    const error = new Error(err)
                    error.data = data
                    reject(error)
                  } else {
                    resolve(data)
                  }
                })
              })
              // if (code) {
              //   this.tfMessage = `Whoops! Your device has some issue, [code=${code}]`
              //   this.logining = false
              // } else {
                // TDID.activateSign(deviceToken)
                this.deviceToken = deviceToken// TDID.isActivate() ? TDID.signData(deviceToken) : deviceToken
                this.$nextTick(() => {
                  $('form').submit()
                })
              // }
            } catch(error) {
              // this.tfMessage = error.message
              // this.logining = false
              // this.deviceToken = deviceToken
              const msg = `Whoops! Your device has some issue, [msg=${error.message}, data=${JSON.stringify(error.data)}]`
              console.error(msg)
              alert(msg)
              this.logining = false
              // this.$nextTick(() => {
              //   $('form').submit()
              // })
            }
        //   })
        // })
        // debugger
      } catch(error) {
        console.error(error)
        this.logining = false
      }
    },
    changeCaptcha() {
      this.captchaUrl = `/captcha.gif?${Date.now()}`
    }
  },
}